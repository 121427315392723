.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media screen and (min-width: 0px) and (max-width: 2048px) {
  #customHeader {
    display: none;
  }
}

@media print {
  #hideOnPrint {
    display: none;
  }
  #customHeader {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 15px;
    background-color: white;
    margin-top: -60px;
  }

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .newpage {
    page-break-before: always;
  }

  @page {
    /* margin-top: 0;
    /* size: portrait; */
  }

  /* because the first thing does not hide everything */
  .MuiDrawer-root,
  .MuiToolbar-root {
    display: none !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
